import { Auth0Client } from '@auth0/auth0-spa-js';
import { EnvUrlConstants } from '../../constants/app-constants.js';
import jwt_decode from "jwt-decode";

export function getEnvironmentName(){
  const host = window.location.host;
  if (host.includes('-dev'))
    return 'DEV';
  else if(host.includes('-test'))
    return 'TEST';
  else if(host.includes('-uat'))
    return 'UAT';
  else if(host.includes('-preprod'))
    return 'PREPROD';
  else if(host.startsWith('www.lincolnfinancial.com'))
    return 'PROD';
  else if(host.startsWith('lincolnfinancial.com'))
    return 'PROD';
  else
    return 'PROD';
}
let accessToken;
let idToken;
export function setAuthToken(token){
  accessToken = token;
}

export function getAuthToken(){
  return accessToken;
}

export function setIdToken(token){
  idToken = token;
}

export function getIdToken(){
  return idToken;
}

export async function getTokens(){
   let envName = getEnvironmentName();
    const auth0 = new Auth0Client({
    domain: EnvUrlConstants.auth0[envName].auth.domain,
		client_id: EnvUrlConstants.auth0[envName].auth.clientId,
		audience: EnvUrlConstants.auth0[envName].auth.audience,
		redirect_uri: EnvUrlConstants.auth0[envName].auth.redirectUri,
		useRefreshTokens: true,
		httpInterceptor: {
		allowedList: [
			{
			uri: '*',
			tokenOptions: {
				audience: EnvUrlConstants.auth0[envName].auth.audience
				},
			}
		],
		}
      });
   let tokens = {};
   tokens['access'] = await auth0.getTokenSilently();
   let idClaim = await auth0.getIdTokenClaims();
   tokens['identity'] = idClaim.__raw;
      // return token;
      return tokens;
}

export function isTokenExpired(token){
  const decoded = jwt_decode(token);
  console.log('Token Decoded',JSON.stringify(decoded));
  const decodedJwtExp = Number(JSON.stringify(decoded.exp));
  console.log('Exp time : ',decodedJwtExp);
  console.log('Time Now :',Math.floor(Date.now() / 1000));
  if (decodedJwtExp < Math.floor(Date.now() / 1000)) {
  console.log("Authorizer authenticate: token expired.");
  return true;
  }
  else{
  return false;
  }}
