import { getClosest } from '../../core/utilities/utilities';

var ariaLabelBackup = '';
export var reorderedFavCids = [];

export function createDragIcon() {
    var dragIcon = document.createElement('DIV');
    dragIcon.setAttribute('class', 'drag-icon draggable-target');
    //add 4 inner DIVS to dragIcon (<div class="draggable-target"></div>)
    for (var i = 0; i < 4; i++) {
        var innerIconDiv = document.createElement('DIV');
        innerIconDiv.setAttribute('class', 'draggable-target');
        dragIcon.appendChild(innerIconDiv);
    }
    return dragIcon;
}

export function sortable(UIlist, onUpdate) {
    var dragEl, nextEl;

    function _onDragOver(evt) {
        var positionY = null;
        evt.preventDefault();
        if (evt.dataTransfer) {
            evt.dataTransfer.dropEffect = 'move';
        }

        if (evt.type === 'dragover') {
            var target = evt.target;
            positionY = evt.clientY;
            dragEl.focus();
            if (target && target !== dragEl && target.nodeName == 'LI') {
                var rect = target.getBoundingClientRect();
                var next = (evt.clientY - rect.top) / (rect.bottom - rect.top) > .5;
                UIlist.insertBefore(dragEl, next && target.nextSibling || target);
            }
        }
        if (evt.type === 'touchmove') {
            evt.preventDefault();
            positionY = evt.touches[0].clientY;
            var UIListItems = UIlist.querySelectorAll('.myfavorites__item:not(.myfavorites__item--noFav)');
            var UIListItemRects = [];
            for (var ui = 0; ui < UIListItems.length; ui++) {
                var UIListObj = {
                    element: UIListItems[ui],
                    rect: UIListItems[ui].getBoundingClientRect()
                }
                UIListItemRects.push(UIListObj);
            }
            var touchY = evt.touches[0].clientY + 15;
            var isShowMore = document.querySelector('.myfavorites__items--display-5');
            _touchGhostPostion(evt.touches[0].clientY, dragEl);

            for (var ur = 0; ur < UIListItemRects.length; ur++) {
                if (touchY > UIListItemRects[ur].rect.top - 1 && touchY < UIListItemRects[ur].rect.bottom + 1) {
                    var dropTarget = UIListItemRects[ur].element;
                    if (dragEl && dragEl !== dropTarget) {
                        console.log('dropTarget:', dropTarget);
                        var rect = UIListItemRects[ur].rect;
                        var next = (evt.clientY - rect.top) / (rect.bottom - rect.top) > .5;
                        if (((isShowMore && ur === 4) || ur === UIListItemRects.length - 1) && (touchY - 30 > UIListItemRects[ur].rect.top - 1 && touchY < UIListItemRects[ur].rect.bottom + 1)) {
                            UIlist.insertBefore(next && dropTarget.nextSibling || dropTarget, dragEl);
                        } else {
                            UIlist.insertBefore(dragEl, next && dropTarget.nextSibling || dropTarget);
                        }
                    }
                    break;
                }
            }
        }
        setTimeout(function() {
            var showMore = document.querySelector('.myfavorites__btn');
            if (showMore && showMore.querySelector('a').textContent === 'SHOW MORE' && positionY + 3 > showMore.getBoundingClientRect().top) {
                //Toggle Show More button
                if (document.createEvent) {
                    var toggle_event = document.createEvent('HTMLEvents');
                    toggle_event.initEvent('toggle_show_more', true, true);
                    document.dispatchEvent(toggle_event);
                } else {
                    var toggle_event = new Event('toggle_show_more');
                    dispatchEvent(toggle_event);
                }
            }
        }, 500);
    }

    function _onDragEnd(evt) {
        evt.preventDefault();
        if (dragEl) {
            dragEl.classList.remove('ghost');
            dragEl.blur();
            if (evt.type === 'touchend') {
                UIlist.removeEventListener('touchmove', _onDragOver, false);
                UIlist.parentElement.removeEventListener('touchend', _onDragEnd, false);
            } else {
                UIlist.removeEventListener('dragover', _onDragOver, false);
                UIlist.removeEventListener('dragend', _onDragEnd, false);
            }
            if (nextEl !== dragEl.nextSibling) {
                onUpdate(dragEl);
            }
        }
    }

    UIlist.addEventListener('dragstart', function(evt) {
        if (evt.target.nodeName === 'LI') {
            dragEl = evt.target;
        } else {
            dragEl = getClosest(evt.target, 'LI');
        }
        if (dragEl) {
            nextEl = dragEl.nextSibling;

            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.setData('Text', dragEl.textContent);
            dragEl.focus();

            UIlist.addEventListener('dragover', _onDragOver, false);
            UIlist.addEventListener('dragend', _onDragEnd, false);

            setTimeout(function() {
                dragEl.classList.add('ghost');
            }, 0);
        }
    }, false);

    UIlist.addEventListener('mousedown', function(evt) {
        if (evt.target.nodeName === 'DIV' || evt.target.nodeName === 'LI' &&
            !evt.target.classList.contains('myfavorites-save-cancel') && !evt.target.classList.contains('myfavorites__item--noFav')) {
            dragEl = getClosest(evt.target, 'LI');
            setTimeout(function() {
                if (dragEl) dragEl.focus();
            }, 0);
        }
    });

    UIlist.addEventListener('touchstart', function(evt) {
        if (evt.target.nodeName === 'DIV' || evt.target.nodeName === 'LI' &&
            !evt.target.classList.contains('myfavorites-save-cancel') && !evt.target.classList.contains('myfavorites__item--noFav')) {
            evt.preventDefault();
            dragEl = getClosest(evt.target, 'LI');
            nextEl = dragEl.nextSibling;
            //Enable or Disable drag and drop
            UIlist.addEventListener('touchmove', _onDragOver, false);
            UIlist.parentElement.addEventListener('touchend', function(evt) {
                if (!evt.target.classList.contains('myfavorites-save') &&
                    !evt.target.parentElement.classList.contains('myfavorites-save') &&
                    !evt.target.classList.contains('myfavorites-cancel') &&
                    !evt.target.parentElement.classList.contains('myfavorites-cancel') &&
                    !evt.target.classList.contains('myfavorites__btn') &&
                    !evt.target.classList.contains('fav-remove-link')
                ) {
                    _onDragEnd(evt);
                }
            }, false);

            setTimeout(function() {
                dragEl.classList.add('ghost');
                _touchGhostPostion(evt.touches[0].clientY, dragEl);
            }, 0);
        }
    }, false);

    UIlist.parentElement.addEventListener('touchend', function(evt) {
        console.log('touchend:', evt);
        if (!evt.target.classList.contains('myfavorites-save') &&
            !evt.target.parentElement.classList.contains('myfavorites-save') &&
            !evt.target.classList.contains('myfavorites-cancel') &&
            !evt.target.parentElement.classList.contains('myfavorites-cancel') &&
            !evt.target.classList.contains('myfavorites__btn') &&
            !evt.target.classList.contains('fav-remove-link')
        ) {
            setTimeout(function() {
                if (dragEl) dragEl.classList.remove('ghost');
                var touchGhost = document.querySelector('.myfavorites__item--touch-ghost');
                if (touchGhost) {
                    touchGhost.remove(); //requires Element.remove() polyfill for IE
                }
            }, 0);
        }
    }, false);
}

export function sortList(UIList) {
    var favUIListEls = UIList.querySelectorAll('li.myfavorites__item');
    reorderedFavCids = [];
    for (var v = 0; v < favUIListEls.length; v++) {
        reorderedFavCids.push(favUIListEls[v].querySelector('a').attributes['data-event-assetid'].value);
    }
    console.log('reorderedFavCids:', reorderedFavCids);
    //Enable Save Changes
    if (document.createEvent) {
        var save_enable_event = document.createEvent('HTMLEvents');
        save_enable_event.initEvent('enable_save_changes', true, true);
        document.dispatchEvent(save_enable_event);
    } else {
        var save_enable_event = new Event('enable_save_changes');
        dispatchEvent(save_enable_event);
    }
}

export function dragDropKeyboardSupport(favUIListItem, favoritesData, deletedFavCids) {
    //ADA - Drag/Drop
    favUIListItem.addEventListener('focusout', function(event) {
        var focusedItem = document.querySelector('.myfavorites__item--drag');
        if (focusedItem) {
            focusedItem.classList.remove('myfavorites__item--drag');
        }
        if (ariaLabelBackup) {
            setTimeout(function() {
                event.target.setAttribute('aria-label', ariaLabelBackup);
                ariaLabelBackup = '';
            }, 50);
        }
    });
    favUIListItem.addEventListener('focus', function(event) {
        if (ariaLabelBackup === '') {
            ariaLabelBackup = event.target.getAttribute('aria-label');
        }
    });
    favUIListItem.addEventListener('keydown', function(event) {
        if (event.target.classList.contains('myfavorites__item') && [32, 37, 38, 39, 40].indexOf(event.keyCode) > -1) {
            event.preventDefault();
            var container = event.target.parentElement;
            switch (event.keyCode) {
                case 32: // event for space key
                    if (event.target.classList.contains('myfavorites__item--drag')) {
                        event.target.setAttribute('aria-label', 'dropped');
                        event.target.classList.remove('myfavorites__item--drag');
                        sortList(favUIListItem.parentElement);
                    } else {
                        event.target.setAttribute('aria-label', 'lifted');
                        event.target.classList.add('myfavorites__item--drag');
                    }
                    break;
                case 38: // event for up arrow key
                case 37: // event for right arrow key
                    if (event.target.classList.contains('myfavorites__item--drag')) {
                        var prevEle = event.target.previousSibling;
                        if (prevEle && prevEle.classList && prevEle.classList.contains('myfavorites__item')) {
                            container.insertBefore(prevEle, event.target.nextSibling);
                            var position = _getFavItemPosition(event.target) + ' of ' + (favoritesData.length - deletedFavCids.length);
                            event.target.setAttribute('aria-label', 'moved up to position ' + position);
                        } else {
                            event.target.setAttribute('aria-label', 'alert');
                            setTimeout(function() {
                                event.target.setAttribute('aria-label', 'reached first position');
                            }, 50);
                        }
                    }
                    break;
                case 40: // event for down arrow key
                case 39: // event for left arrow key
                    if (event.target.classList.contains('myfavorites__item--drag')) {
                        var nextEle = event.target.nextSibling;
                        if (nextEle && nextEle.classList.contains('myfavorites__item')) {
                            var isFocusable = document.querySelector('.myfavorites__items--display-5');
                            var position = (_getFavItemPosition(event.target) + 1) + ' of ' + (favoritesData.length - deletedFavCids.length);
                            event.target.setAttribute('aria-label', 'moved down to position ' + position);
                            if (isFocusable) {
                                if (_getFavItemPosition(event.target) === 5) {
                                    //Toggle Show More button
                                    if (document.createEvent) {
                                        var toggle_event = document.createEvent('HTMLEvents');
                                        toggle_event.initEvent('toggle_show_more', true, true);
                                        document.dispatchEvent(toggle_event);
                                    } else {
                                        var toggle_event = new Event('toggle_show_more');
                                        dispatchEvent(toggle_event);
                                    }
                                }
                            }
                            container.insertBefore(nextEle, event.target);
                        } else {
                            event.target.setAttribute('aria-label', 'alert');
                            setTimeout(function() {
                                event.target.setAttribute('aria-label', 'reached last position');
                            }, 50);
                        }
                    }
                    break;
            }
        }
    });
}

function _getFavItemPosition(ele) {
    var children = ele.parentNode.children;
    var index = children.length - 1;
    for (; index >= 0; index--) {
        if (ele == children[index]) break;
    }
    return index + 1; // position of element
}

function _touchGhostPostion(touchY, dragEl) {
    if (dragEl) {
        // duplicate element and move according the touch move
        var touchGhost = document.querySelector('.myfavorites__item--touch-ghost');
        var parent = document.querySelector('.myfavorites-component.myfavorites');
        if (!touchGhost) {
            var cln = dragEl.cloneNode(true);
            cln.classList.add('myfavorites__item--touch-ghost');
            cln.classList.remove('ghost');
            cln.style.width = dragEl.offsetWidth + 'px';
            parent.appendChild(cln);
            touchGhost = document.querySelector('.myfavorites__item--touch-ghost');
        }
        touchGhost.style.top = touchY - parent.getBoundingClientRect().top - 20 + 'px';
    }
}