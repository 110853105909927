import { updateFavoritesData } from './favorites';
import DOMPurify from 'dompurify';
import { sortable, sortList, dragDropKeyboardSupport, createDragIcon, reorderedFavCids } from './favorites-drag-drop';

export var deletedFavCids = [];
export var isMyFavoritesExpanded = false;
export var UIlist = document.querySelector('ul.myfavorites__items');

export function populateMyFavorites(favData) {
    var listItems = UIlist.querySelectorAll('li.myfavorites__item');
    if (favData) {
        for (var q = 0; q < listItems.length; q++) {
            listItems[q].remove();
        }

        _noFavorites(favData.length);

        if (favData.length > 5 && document.querySelector('.myfavorites__btn')) {
            document.querySelector('.myfavorites__btn').style.display = 'block';
        } else {
            document.querySelector('.myfavorites__btn').style.display = 'none';
        }

        for (var r = 0; r < favData.length; r++) {
            //create entries for Favorites
            var favLI = document.createElement('LI');
            var favLink = document.createElement('A');
            var favDIV = document.createElement('DIV');
            var removeIcon = document.createElement('I');

            favLI.setAttribute('class', 'myfavorites__item');
            favLI.setAttribute('draggable', 'true');
            favLI.setAttribute('tabindex', r > 4 ? -1 : 0);
            favLI.setAttribute('role', 'menuitem');

            //ADA - Drag/Drop
            dragDropKeyboardSupport(favLI, favData, deletedFavCids);

            favDIV.setAttribute('class', 'myfavorites-component__item-link');
            favLink.setAttribute('draggable', 'false');
            favLink.setAttribute('href', DOMPurify.sanitize(favData[r].url));
            favLink.setAttribute('data-event-assetid', favData[r].id);
            favLink.setAttribute('data-event-name', favData[r].title);
            favLink.setAttribute('data-event-action', 'link');
            favLink.setAttribute('data-event-type', 'hlink');
            if (r > 4) {
                favLink.setAttribute('tabindex', -1);
            }
            favLink.innerHTML = DOMPurify.sanitize(favData[r].title);
            favDIV.appendChild(favLink);
            favLI.setAttribute('aria-label', favLink.textContent.trim() + ' draggable');

            var dragIcon = createDragIcon();
            removeIcon.setAttribute('class', 'fas fa-minus fav-remove-link');
            removeIcon.setAttribute('id', 'remove-' + favData[r].id);
            removeIcon.setAttribute('aria-label', 'remove');
            removeIcon.setAttribute('tabindex', r > 4 ? -1 : 0);
            removeIcon.setAttribute('role', 'link');
            removeIcon.addEventListener('click', function(event) {
                _softDelete(event);
            });
            removeIcon.addEventListener('keyup', function(event) {
                if (event.which === 13 || event.which === 32) {
                    event.preventDefault();
                    _softDelete(event);
                }
            });
            removeIcon.addEventListener('keydown', function(event) {
                if (event.which === 13 || event.which === 32) {
                    event.preventDefault();
                }
            });

            favLI.appendChild(dragIcon);
            favLI.appendChild(favDIV);
            favLI.appendChild(removeIcon);
            UIlist.appendChild(favLI);
        }

        UIlist.addEventListener('drop', function(event) {
            event.preventDefault();
        });

        sortable(UIlist, function(item) {
            sortList(UIlist);
        });

        toggleSaveBtn(false);
    }
}

export async function saveChanges(event) {
    //if pages have been reorderd, overwrite
    if (reorderedFavCids.length > 0) {
        window['favCIDs'] = reorderedFavCids;
    }
    //remove "deleted" ID's from favCids array
    for (var t = 0; t < deletedFavCids.length; t++) {
        var idx = window['favCIDs'].indexOf(deletedFavCids[t]);
        if (idx > -1) {
            window['favCIDs'].splice(idx, 1);
        }
    }
    deletedFavCids = [];
    await updateFavoritesData(event);
    toggleSaveBtn(false);
}

export function cancelChanges() {
    //reset list
    if (window.sessionStorage && window.sessionStorage.pageLevelFavoritesData) {
        populateMyFavorites(JSON.parse(window.sessionStorage.pageLevelFavoritesData));

    }
    deletedFavCids = [];

    if (UIlist.querySelectorAll('.myfavorites__item:not(.myfavorites__item--noFav)').length > 5) {
        document.querySelector('.myfavorites__btn').style.display = 'block';
        if (isMyFavoritesExpanded) {
            toggleShowMoreBtn(document.querySelector('.myfavorites__btn'));
        }
    } else {
        document.querySelector('.myfavorites__btn').style.display = 'none';
    }
}

export function toggleShowMoreBtn(btn) {
    console.log('toggleShowMoreBtn called');
    var buttonIcon = btn.querySelector('i');
    var favoritesItem = document.querySelector('.myfavorites__items');
    if (favoritesItem.classList.contains('myfavorites__items--display-5')) {
        favoritesItem.classList.remove('myfavorites__items--display-5');
        buttonIcon.classList.add('fa-minus');
        buttonIcon.classList.remove('fa-plus');
        btn.querySelector('a').innerText = "SHOW LESS";
        isMyFavoritesExpanded = true;
        _addOrRemoveFocus(true);
        if (!document.querySelector('.myfavorites__item--drag')) {
            var favListItems = document.querySelectorAll('.myfavorites__item');
            var len = favListItems.length;
            if(len > 6)
            {
                var sixthElem = favListItems[6];
                sixthElem.focus();
            }         
        }
    } else {
        favoritesItem.classList.add('myfavorites__items--display-5');
        buttonIcon.classList.add('fa-plus');
        buttonIcon.classList.remove('fa-minus');
        btn.querySelector('a').innerText = "SHOW MORE";
        isMyFavoritesExpanded = false;
        _addOrRemoveFocus(false);
    }
}

export function toggleSaveBtn(enable) {
    if (enable) {
        //ENABLE save button
        var disabledSaveChangesBtn = document.querySelector('.myfavorites-component .saveChangesDisbleClass');
        if (disabledSaveChangesBtn) {
            disabledSaveChangesBtn.classList.add('saveChangesEnableClass');
            disabledSaveChangesBtn.classList.remove('saveChangesDisbleClass');
            disabledSaveChangesBtn.setAttribute('tabindex', '0');
        }
    } else {
        //DISABLE save button
        var enabledSaveChangesBtn = document.querySelector('.myfavorites-component .saveChangesEnableClass');
        if (enabledSaveChangesBtn) {
            enabledSaveChangesBtn.classList.add('saveChangesDisbleClass');
            enabledSaveChangesBtn.classList.remove('saveChangesEnableClass');
            enabledSaveChangesBtn.setAttribute('tabindex', '-1');
        }
    }
}

function _addOrRemoveFocus(isFocus) {
    setTimeout(function() {
        var favoritesItems = document.querySelectorAll('.myfavorites__item:not(.myfavorites__item--noFav');
        console.log(favoritesItems);
        if (favoritesItems.length > 5) {
            for (var p = 5; p < favoritesItems.length; ++p) {
                console.log(p);
                favoritesItems[p].setAttribute('tabindex', isFocus ? 0 : -1);
                favoritesItems[p].querySelector('a').setAttribute('tabindex', isFocus ? '' : -1);
                favoritesItems[p].querySelector('.fav-remove-link').setAttribute('tabindex', isFocus ? 0 : -1);
            }
        }
    });
}

function _findFocusElement(isPrevious, ele) {
    var focusEle = isPrevious ? ele.nextSibling : ele.previousSibling;
    if (focusEle.classList && focusEle.classList.contains('myfavorites__item')) {
        focusEle.querySelector('.fav-remove-link').focus();
        setTimeout(function() {
            focusEle.querySelector('.fav-remove-link').focus();
        });
    } else {
        _findFocusElement(false, ele);
    }
}

function _softDelete(event) {
    //hide item from list UI
    var deletedID = event.target.getAttribute('id').replace('remove-', '');
    var UIListItems = UIlist.querySelectorAll('.myfavorites__item:not(.myfavorites__item--noFav)');
    deletedFavCids.push(deletedID);
    toggleSaveBtn(true);

    if (UIListItems.length > 6) {
        document.querySelector('.myfavorites__btn').style.display = 'block';
    } else {
        if (!isMyFavoritesExpanded) {
            toggleShowMoreBtn(document.querySelector('.myfavorites__btn'));
        }
        document.querySelector('.myfavorites__btn').style.display = 'none';
    }

    if (event.type === 'keyup') {
        if (UIListItems.length - 1) {
            _findFocusElement(true, event.target.parentElement);
        } else {
            document.querySelector('.myfavorites-cancel').focus();
        }
    }

    if (UIListItems.length === 1) {
        _noFavorites(0);
    }
    event.target.parentElement.remove();
}

function _noFavorites(count) {
    var noFav = document.querySelector('.myfavorites__item--noFav');
    if (noFav) {
        if (count > 0) {
            noFav.style.display = 'none';
        } else {
            noFav.style.display = 'flex';
        }
    }
}

(function() {
    // Element.remove() polyfill
    if (!('remove' in Element.prototype)) {
        Element.prototype.remove = function() {
            if (this.parentNode) {
                this.parentNode.removeChild(this);
            }
        };
    }
})();