import { getClosest, makePostCall } from '../../core/utilities/utilities';
import { cancelChanges, populateMyFavorites, toggleShowMoreBtn, toggleSaveBtn, saveChanges } from './favorites-myfavorites';
import { getEnvironmentName } from '../../core/utilities/auth0-token';
import { initPageAddRemoveLink, isPageFavorite, togglePageFavLink } from './favorites-add-remove';

var FAVORITES_CONFIG = {
    'SERVICE_URL':
    {
        'DEV': 'https://favorites.lfdapix1-dev.web.lfg.com/advisorMyFavorites',
        'TEST': 'https://favorites.lfdapix1-test.web.lfg.com/advisorMyFavorites',
        'PROD': 'https://favorites.lfdapix1.web.lfg.com/advisorMyFavorites'
    },
    'GET_SERVICE_ID': 'INT_FAVORITES_GET',
    'UPDATE_SERVICE_ID': 'INT_FAVORITES_UPDATE',
    'MAX_RESULTS': 15
}
export function getServiceEnv(){
    var env = getEnvironmentName();
    if(env !== 'DEV' && env !== 'PROD'){
        env = 'TEST';
    }
    return env;
}
export async function callFavoritesService() {
    var serviceEnv = getServiceEnv();
    window.favCallInProgress = 1;
    var dataFromSession = window.sessionStorage.getItem('pageLevelFavoritesData');

    if(dataFromSession!==null){
        console.log("Populating Data from Session");
        var responseData= JSON.parse(dataFromSession);
        setFavouritesData(responseData);
    }
    else{
        //window.localStorage.setItem('pageLevelFavoritesData', '');
        window.localStorage.removeItem('pageLevelFavoritesData');
    var favoritesRequest = {
       // "SERVICE_ID": FAVORITES_CONFIG.GET_SERVICE_ID,
        "s": window['siteName'],
        "wsuid": window['WSUID']
    };

    if (window["baseMarginal"].IS_DELEGATOR) {
        favoritesRequest['DLGT_USER_ID'] = window["baseMarginal"].DLGT_USER_ID;
    } else {
        favoritesRequest['DLGT_USER_ID'] = '';
    }
        await makePostCall(FAVORITES_CONFIG.SERVICE_URL[serviceEnv] + '/getFavorites', favoritesRequest, function(response) {
            window.favCallInProgress = 0;
            if (response && response.responseStatus == 'SUCCESS') {
                if (response.data.favorites) {

                    window.localStorage.setItem('pageLevelFavoritesData', JSON.stringify(response.data.favorites));
                    window.sessionStorage.setItem('pageLevelFavoritesData', JSON.stringify(response.data.favorites));
                    //update header component
                    setFavouritesData(response.data.favorites);

                } else {
                    console.log('no favorites found - no change on link');
                }
            }
        });
    }


}

export function setFavouritesData(responseData){
    if (responseData) {
        window['favCIDs'] = [];
        for (var d = 0; d < responseData.length; d++) {
            window['favCIDs'].push(responseData[d].id);
        }
        togglePageFavLink(isPageFavorite());
        //update header component
        if (document.createEvent) {
            var favorites_event = document.createEvent('HTMLEvents');
            favorites_event.initEvent('updated_favorites', true, true);
            document.dispatchEvent(favorites_event);
        } else {
            var favorites_event = new Event('updated_favorites');
            dispatchEvent(favorites_event);
        }

        if (document.querySelector('section.myfavorites-component')) {
            populateMyFavorites(responseData);
        }
        toggleHeaderFav();
    } else {
        console.log('no favorites found - no change on link');
    }
}

export async function updateFavoritesData(event) {
    var serviceEnv = getServiceEnv();
    var loader = document.querySelector('.lfg-header-loader');
    var isAddFav = true;
    /* var pageLevelFavLink = document.querySelector('.lfg-favorites--add') || document.querySelector('.lfg-favorites--remove');
    if (pageLevelFavLink) {
        pageLevelFavLink.classList.add('lfg-favorites--disabled');
    } */
    if (event.target.classList.contains('lfg-favorites--add') || getClosest(event.target, '.lfg-favorites--add')) {
        // add alert modal on maximum limit
        if (window['favCIDs'].length >= FAVORITES_CONFIG.MAX_RESULTS) {
            _callFavoritesAlert();
            var doUpdate = false;
        } else {
            //add current page ID to fav list
            window['favCIDs'].push(window['navData'].currPageId);
            var postCIDs = window['favCIDs'];
            var doUpdate = true;
        }
    }
    if (event.target.classList.contains('lfg-favorites--remove') || getClosest(event.target, '.lfg-favorites--remove')) {
        isAddFav = false;
        //remove current page ID from fav list
        for (var f = 0; f < window['favCIDs'].length; f++) {
            if (window['favCIDs'][f].toString() === window['navData'].currPageId.toString()) {
                window['favCIDs'].splice([f], 1);
                var postCIDs = window['favCIDs'];
                var doUpdate = true;
                break;
            }
        }
    }
    if (event.target.parentElement.classList.contains('myfavorites-save-cancel')) {
        var postCIDs = window['favCIDs'];
        var doUpdate = true;
    }
    if (doUpdate) {
        var favoritesUpdateRequest = {
            //"SERVICE_ID": FAVORITES_CONFIG.UPDATE_SERVICE_ID,
            "cid": postCIDs.length > 0 ? postCIDs.toString() : ',',
            "s": window['siteName'],
            "wsuid": window['WSUID']
        };

        if (window["baseMarginal"].IS_DELEGATOR) {
            favoritesUpdateRequest['DLGT_USER_ID'] = window["baseMarginal"].DLGT_USER_ID;
        } else {
            favoritesUpdateRequest['DLGT_USER_ID'] = '';
        }

        var favsLoaderTimeout = setTimeout(function() {
            if (loader) {
                loader.style.display = 'block';
            }
        }, 500);
       await makePostCall(FAVORITES_CONFIG.SERVICE_URL[serviceEnv] + '/updateFavorites', favoritesUpdateRequest, function(response) {
            clearTimeout(favsLoaderTimeout);
            if (loader) {
                loader.style.display = 'none';
            }
            if (response && response.responseStatus == 'SUCCESS') {
                if (!event.target.classList.contains('myfavorites-save')) {
                    var dataInfo = {
                        'event_name': window.location.pathname,
                        'event_action': 'link',
                        'event_type': 'favorites',
                        'event_version': isAddFav ? 'add' : 'remove'
                    };
                    _callSearchAnalytics(dataInfo);
                }
                if (response.data.favorites) {
                    window['favCIDs'] = [];
                    //evaluate if page is still favorited, if so, toggle link
                    for (var g = 0; g < response.data.favorites.length; g++) {
                        window['favCIDs'].push(response.data.favorites[g].id);
                    }
                    window.localStorage.setItem('pageLevelFavoritesData', JSON.stringify(response.data.favorites));
                    window.sessionStorage.setItem('pageLevelFavoritesData', JSON.stringify(response.data.favorites));
                    if (document.querySelector('section.myfavorites-component')) {
                        populateMyFavorites(response.data.favorites);
                    }
                    toggleHeaderFav();
                    togglePageFavLink(isPageFavorite());
                } else {
                    console.log(response.error);
                }
            } else {
                _callFavoritesErrorModal();
                console.log(response.error);
            }
        });
    }
}

export function toggleHeaderFav() {
    if (document.createEvent) {
        var favorites_event = document.createEvent('HTMLEvents');
        favorites_event.initEvent('updated_favorites', true, true);
        document.dispatchEvent(favorites_event);
    } else {
        var favorites_event = new Event('updated_favorites');
        dispatchEvent(favorites_event);
    }
    setTimeout(function() {
        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
            let evt = document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        } else {
            window.dispatchEvent(new Event('resize'));
        }
    }, 100);
}

function _callFavoritesAlert() {
    var isAlertModalExist = document.querySelector('#favorites-alert-link');
    if (isAlertModalExist) {
        document.querySelector('#favorites-alert-link').click();
    } else {
        var alertDOM = document.createElement("div");
        var innerDOM = '<a href="#" aria-hidden="true" id="favorites-alert-link" data-target="modal_favorites_alert" data-toggle="modal">modal alert</a>' +
            '<section class="modal">' +
            '<div class="modal-popup-cont modal-popup-cont-wcs modal-popup-cont--favorites-alert">' +
            '<section id="modal_favorites_alert" class="modal-popup modal-popup--sm" role="modal" tabindex="0">' +
            '<a aria-label="close link" class="modal-popup__close" href="javascript:void(0)" data-dismiss="modal">' +
            '<i class="fas fa-times"></i>' +
            '</a>' +
            '<header class="modal-popup__header">' +
            '<h2 class="lfg-color">Attention</h2>' +
            '</header>' +
            '<div class="modal-popup__body">' +
            '<p>You have reached the maximum number (15) of favorites allowed. Please remove a favorite from the current list to add the current page.</p>' +
            '</div>' +
            '<a aria-hidden="true" class="modal-popup__focus-trap--wcs" href="javascript:void(0);">focus trap</a>' +
            '</section>' +
            '</div>' +
            '</section>';
        alertDOM.innerHTML = innerDOM;
        document.querySelector('body').appendChild(alertDOM);
        document.querySelector('#favorites-alert-link').click();
        document.querySelector('.modal-popup-cont--favorites-alert .modal-popup__focus-trap--wcs').addEventListener('focus', function(event) {
            event.target.parentElement.querySelector('.modal-popup__close').focus();
        });
    }
    var dataInfo = {
        'event_name': 'max number of favorites',
        'event_type': 'favorites',
        'event_action': 'error'
    };
    _callSearchAnalytics(dataInfo);
}

function _callFavoritesErrorModal() {
    var isAlertModalExist = document.querySelector('#favorites-error-link');
    if (isAlertModalExist) {
        document.querySelector('#favorites-error-link').click();
    } else {
        var errorModalDOM = document.createElement("div");
        var errorModalInnerDOM = '<a href="#" aria-hidden="true" id="favorites-error-link" data-target="modal_favorites_error" data-toggle="modal">modal error</a>' +
            '<section class="modal">' +
            '<div class="modal-popup-cont modal-popup-cont-wcs modal-popup-cont--favorites-alert">' +
            '<section id="modal_favorites_alert" class="modal-popup modal-popup--sm" role="modal" tabindex="0">' +
            '<a aria-label="close link" class="modal-popup__close" href="javascript:void(0)" data-dismiss="modal">' +
            '<i class="fas fa-times"></i>' +
            '</a>' +
            '<header class="modal-popup__header">' +
            '<h2 class="lfg-color">Attention</h2>' +
            '</header>' +
            '<div class="modal-popup__body">' +
            '<div class="alert-message alert-message--error">We are currently experiencing technical difficulties adding this page to your favorites. Please try again later.</div>' +
            '</div>' +
            '<a aria-hidden="true" class="modal-popup__focus-trap--wcs" href="javascript:void(0);">focus trap</a>' +
            '</section>' +
            '</div>' +
            '</section>';
        errorModalDOM.innerHTML = errorModalInnerDOM;
        document.querySelector('body').appendChild(errorModalDOM);
        document.querySelector('#favorites-error-link').click();
        document.querySelector('.modal-popup-cont--favorites-error .modal-popup__focus-trap--wcs').addEventListener('focus', function(event) {
            event.target.parentElement.querySelector('.modal-popup__close').focus();
        });
    }
    var dataInfo = {
        'event_name': 'We are currently experiencing technical difficulties adding this page to your favorites. Please try again later.',
        'event_type': 'favorites',
        'event_action': 'error'
    };
    _callSearchAnalytics(dataInfo);
}

function _callSearchAnalytics(data) {
    if (typeof utag !== 'undefined') {
        utag.link(data);
    }
}

(function() {
    var isFavorite = false;
    // Element.remove() polyfill
    if (!('remove' in Element.prototype)) {
        Element.prototype.remove = function() {
            if (this.parentNode) {
                this.parentNode.removeChild(this);
            }
        };
    }

    window.addEventListener("load", async function() {
        if (
            (window['navData'] && window['navData'].userFavorites) ||
            (
                window.sessionStorage.AudienceFavorites &&
                JSON.parse(window.sessionStorage.AudienceFavorites).state &&
                JSON.parse(window.sessionStorage.AudienceFavorites).site === window['siteName']
            )
        ) {
            if (typeof window.favCallInProgress === "undefined") {
              await initFavoritesData();
            }
            if (!window.sessionStorage.AudienceFavorites) {
                var audienceObj = {
                    state: true,
                    site: window['siteName']
                }
                window.sessionStorage.setItem('AudienceFavorites', JSON.stringify(audienceObj));
            }
            initPageAddRemoveLink();
        }

        var save = document.querySelector('.myfavorites-component .myfavorites-save');
        var cancel = document.querySelector('.myfavorites-component .myfavorites-cancel');

        // Save Changes button
        if (save) {
            save.setAttribute('data-event-action', 'button');
            save.setAttribute('data-event-name', 'save changes');
            save.setAttribute('data-event-type', 'favorites');
            save.addEventListener('click', async function(event) {
               await saveChanges(event);
            });
        }
        // Cancel button
        if (cancel) {
            cancel.setAttribute('data-event-action', 'button');
            cancel.setAttribute('data-event-name', 'cancel');
            cancel.setAttribute('href', '');
            cancel.setAttribute('data-event-type', 'favorites');
            cancel.addEventListener('click', function(event) {
                event.preventDefault();
                cancelChanges();
            });
        }

        var showMoreBtn = document.querySelector('.myfavorites__btn');
        if (showMoreBtn) {
            showMoreBtn.querySelector('a').addEventListener('click', function(event) {
                event.preventDefault();
                toggleShowMoreBtn(showMoreBtn);
            });
            showMoreBtn.querySelector('a').addEventListener('keydown', function(event) {
                if (event.which === 13) {
                    event.preventDefault();
                    toggleShowMoreBtn(showMoreBtn);
                    // var favListItems = document.querySelectorAll('.myfavorites__item');
                    // var sixthElem = favListItems[6];
                    // sixthElem.focus();
                }
            });
        }
    });

    //Copy value from localStorage to sessionStorage once something gets added to localStorage
    window.addEventListener('storage', (event) => {
        console.log('Copying Data from Local to session storage');
      if(event.key == 'pageLevelFavoritesData'){
        console.log(JSON.stringify(event.newValue));
        window.sessionStorage.setItem('pageLevelFavoritesData',event.newValue);
      }
     });

    document.addEventListener('visibilitychange', async function() {
        var siteName= window['siteName'];
        if (((window['navData'] && window['navData'].userFavorites) ||
                document.querySelector('.lfg-favorites')) && !document.hidden && siteName!=='SecureBackOffice' && siteName!=='SecureRIA') {
            // Update favorites list from other pages
          await  callFavoritesService();
        }
    });

    //sync page with header component
    window.addEventListener("updated_favorites_Component", function() {
        //isFavorite = false;
        var favoritesResults = JSON.parse(window.sessionStorage.pageLevelFavoritesData);
        window['favCIDs'] = [];
        for (var f = 0; f < favoritesResults.length; f++) {
            window['favCIDs'].push(favoritesResults[f].id);
        }
        togglePageFavLink(isPageFavorite());
        if (document.querySelector('.myfavorites-component')) {
            if (window.sessionStorage && window.sessionStorage.pageLevelFavoritesData) {
                populateMyFavorites(JSON.parse(window.sessionStorage.pageLevelFavoritesData));
            }
        }
    })

    document.addEventListener("toggle_show_more", function() {
        toggleShowMoreBtn(document.querySelector('.myfavorites__btn'));
    });

    document.addEventListener("enable_save_changes", function() {
        toggleSaveBtn(true);
    });

    async function initFavoritesData() {
        if (typeof window['favCIDs'] !== "undefined" && window['favCIDs'].length > 0 && !document.querySelector('section.myfavorites-component')) {
            togglePageFavLink(isPageFavorite());
        } else {
           await callFavoritesService();
        }
    }
})();
