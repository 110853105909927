export const EnvUrlConstants = {
    auth0:{
    TEST: {
      auth: {
        domain: 'auth.lincolnfinancial-test.lfg.com',
        clientId: 'lWj2XEq9IvtpshSU2obutBJo0IE5lfeM',
        audience: 'https://lincolnfinancial-test.lfg.com/api',
        redirectUri: '',
        scope: 'profile openid',
        useRefreshTokens: true,
        errorPath: '',
      },
      apiUrl: '',
      lincolnLoginURL: '',
      lincolnLogoutURL: '',
      envName: 'TEST'
    },
    DEV: {
      auth: {
        domain: 'auth.lincolnfinancial-dev.lfg.com',
        clientId: 'cW1aXNOfewp1eObLNkkIybhqKMMdJZny',
        audience: 'https://lincolnfinancial-dev.lfg.com/api',
        redirectUri: '',
        scope: 'profile openid',
        useRefreshTokens: true,
        errorPath: '',     
      },
      apiUrl: '',
      lincolnLoginURL: '',
      lincolnLogoutURL: '',
      envName: 'DEV'
    },
    UAT: {
      auth: {
        domain: 'auth.lincolnfinancial-uat.lfg.com',
        clientId: 'reOlq42OVcxoCf7wwfPP0cTBNrn0zLiX',
        audience: 'https://lincolnfinancial-uat.lfg.com/api',
        redirectUri: '',
        scope: 'profile openid',
        useRefreshTokens: true,
        errorPath: '',
      },
      apiUrl: '',
      lincolnLoginURL: '',
      lincolnLogoutURL: '',
      envName: 'UAT'
    },
    PREPROD: { 
      auth: {
        domain: 'auth.lincolnfinancial-preprod.lfg.com',
        clientId: '76gScGu6eZX7k5JiZMocT1AtvDbiNNRb',
        audience: 'https://lincolnfinancial-preprod.lfg.com/api',
        redirectUri: '',
        scope: 'profile openid',
        useRefreshTokens: true,
        errorPath: '',
      },
      apiUrl: '',
      lincolnLoginURL: '',
      lincolnLogoutURL: '',
      envName: 'PREPROD'
    },
    PROD: { 
      auth: {
        domain: 'auth.lincolnfinancial.com',
        clientId: 'c8nGIT1MQfS6kabf4qBQ2eg7cXDIkPvl',
        audience: 'https://www.lincolnfinancial.com/api',
        redirectUri: '',
        scope: 'profile openid',
        useRefreshTokens: true,
        errorPath: '',
      },
      apiUrl: '',
      lincolnLoginURL: '',
      lincolnLogoutURL: '',
      envName: 'PROD'
    },
    LOCALHOST: { 
      auth: {
        domain: 'auth.lincolnfinancial-test.lfg.com',
        clientId: 'j3nUqeloiSr2cB8VDIhldfAYWfHxYT4l', //LIFE TOWER CLIENT ID
        audience: 'https://lincolnfinancial-test.lfg.com/api',
        redirectUri: '',
        scope: 'profile openid',
        useRefreshTokens: true,
        errorPath: '',
      },
      apiUrl: '',
      lincolnLoginURL: '',
      lincolnLogoutURL: '',
      envName: 'Dev'
    }
    }
  };
  