import { getToken, getAuthToken, isTokenExpired, setAuthToken, setIdToken, getEnvironmentName, getTokens, getIdToken } from '../../core/utilities/auth0-token';
/**
 * Get the closest matching element up the DOM tree.
 * @private
 * @param  {Element} elem     Starting element
 * @param  {String}  selector Selector to match against
 * @return {Boolean|Element}  Returns null if not match found
 */
export function getClosest(elem, selector) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;

};


export async function makePostCall(url, reqBody, callBackFunc) {
    var httpRequest;
    if (window.XMLHttpRequest) { // Mozilla, Safari, IE7+ ...
        httpRequest = new XMLHttpRequest();
    } else if (window.ActiveXObject) { // IE 6 and older
        httpRequest = new ActiveXObject("Microsoft.XMLHTTP");
    }

    httpRequest.open('POST', url, true);
    httpRequest.setRequestHeader('Content-type', 'application/json');
    httpRequest.setRequestHeader('env',getEnvironmentName());
    if(
        getAuthToken() !== undefined && 
        getIdToken() !== undefined && 
        (!isTokenExpired(getAuthToken()))
    ){
        httpRequest.setRequestHeader('Authorization',`Bearer ${getAuthToken()}`);
        //console.log('existing id_token - aws-global-ui:', getIdToken());
        httpRequest.setRequestHeader('X-identity',`Bearer ${getIdToken()}`)
    } else {
        let tokens = await getTokens();
        setAuthToken(tokens.access);
        setIdToken(tokens.identity);
        //console.log('new tokens - aws-global-ui:', tokens);
        httpRequest.setRequestHeader('Authorization',`Bearer ${tokens.access}`);
        httpRequest.setRequestHeader('X-identity',`Bearer ${tokens.identity}`);
    }

    httpRequest.send(JSON.stringify(reqBody));

    httpRequest.onreadystatechange = function() {
        if (httpRequest.readyState === 4) {
            if (httpRequest.status === 200) {
                console.log('keepalive gif good...', httpRequest.status);
                var response = typeof httpRequest.response === 'object' ? httpRequest.response : JSON.parse(httpRequest.response);
                callBackFunc(response);
                // if (parent) {
                //   keepParentSessionAlive();
                // }
                // resetTimer();
            } else {
                console.log('request failed with status : ', httpRequest.status);
                console.log('Error while keep alive session call');
            }
        }
    }
}


