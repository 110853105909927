import { updateFavoritesData } from './favorites';
import { getClosest } from '../../core/utilities/utilities';

export function initPageAddRemoveLink() {
    //Show Add/Remove Favorites link
    if (document.querySelector('.lfg-favorites')) {
        document.querySelector('.lfg-favorites').classList.add('no-global-analytics');
        document.querySelector('.lfg-favorites').style.display = 'block';
        var banner = document.querySelector('.banner');
        if (banner && document.querySelector('.lfg-favorites')) {
            document.querySelector('.lfg-favorites').classList.add('lfg-favorites--banner');
        }
        document.querySelector('.lfg-favorites__link').addEventListener('click', async function(event) {
            event.preventDefault();
            var addRemoveLinkCont = getClosest(event.target, '.lfg-favorites--add') || getClosest(event.target, '.lfg-favorites--remove');
            if (!addRemoveLinkCont.classList.contains('lfg-favorites--disabled')) {
                addRemoveLinkCont.classList.add('lfg-favorites--disabled');
               await updateFavoritesData(event);
            }
        });
    }
}

export function isPageFavorite() {
    var isFavorite = false;
    for (var d = 0; d < window['favCIDs'].length; d++) {
        if (window['favCIDs'][d] === window['navData'].currPageId.toString()) {
            console.log('page is a favorite');
            //toggle page link to "remove" state
            isFavorite = true;
            break;
        }
    }
    return isFavorite;
}

export function togglePageFavLink(isPageFavorite) {
    if (document.querySelector('.lfg-favorites')) {
        var favoritesLink = document.querySelector('.lfg-favorites--add') || document.querySelector('.lfg-favorites--remove') || document.querySelector('.lfg-favorites--disabled');
        favoritesLink.classList.remove('lfg-favorites--disabled');
        if (favoritesLink.classList.contains('lfg-favorites--add') && isPageFavorite) {
            favoritesLink.classList.remove('lfg-favorites--add');
            favoritesLink.classList.add('lfg-favorites--remove');
            favoritesLink.querySelector('.lfg-favorites__action').innerHTML = 'Remove page from';
        }
        if (favoritesLink.classList.contains('lfg-favorites--remove') && !isPageFavorite) {
            favoritesLink.classList.remove('lfg-favorites--remove');
            favoritesLink.classList.add('lfg-favorites--add');
            favoritesLink.querySelector('.lfg-favorites__action').innerHTML = 'Add page to';
        }
    }
}